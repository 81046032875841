import { ChakraProvider, Heading, Text } from "@chakra-ui/react";
import * as React from "react";
import { createRoot } from "react-dom/client";
import useScript from "react-script-hook";
import "./styles.css";
// import { SliseAd } from "@slise/embed-react"

let LOADED_SLOTS = {};

function SliseAd(props: {
  slotId: string;
  style?: React.CSSProperties;
  format: string;
  pub?: string;
}) {
  const addSlot = React.useCallback(() => {
    if (LOADED_SLOTS[props.slotId]) return;

    LOADED_SLOTS[props.slotId] = true;

    let wnd = window as any;
    (wnd.adsbyslise = wnd.adsbyslise || []).push({ slot: props.slotId });
    wnd.adsbyslisesync && wnd.adsbyslisesync();
  }, [props.slotId]);
  React.useEffect(() => {
    // if (LOADED_SCRIPT)
    addSlot();
  }, [addSlot]);
  const [loading, error] = useScript({
    // src: "https://v1.slise.xyz/scripts/embed.js",
    src: "https://v1.slise.xyz/scripts/embed.83f4a203c206cbea51e1.production.js",
    checkForExisting: true,
    async: true,
    onload: () => {
      // LOADED_SCRIPT = true
      // addSlot()
    },
  });
  return (
    <>
      <ins
        className="adsbyslise"
        style={props.style}
        data-ad-slot={props.slotId}
        data-ad-pub={props.pub || "pub-2"}
        data-ad-format={props.format}
      ></ins>
    </>
  );
}

function App() {
  return (
    <div>
      <Heading>Slise test website</Heading>
      Catchmint:
      <SliseAd
        slotId="leaderboard"
        pub="pub-21"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <br />
      <SliseAd
        slotId="mobile"
        pub="pub-21"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      <br />
      LI.FI:
      <SliseAd
        slotId="leaderboard"
        pub="pub-24"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <SliseAd
        slotId="mobile"
        pub="pub-24"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      <br />
      MXC:
      <SliseAd
        slotId="leaderboard"
        pub="pub-20"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <SliseAd
        slotId="mobile"
        pub="pub-20"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      {/* Starkscan:
			<SliseAd slotId="leaderboard" pub="pub-17" format="728x90" style={{ width: '728px', height: '90px' }} /> */}
      Revoke.cash:
      <SliseAd
        slotId="mobile"
        pub="pub-18"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      CoinStats:
      <SliseAd
        slotId="crypto-profit-calculator-leaderboard"
        pub="pub-19"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <br />
      <SliseAd
        slotId="crypto-profit-calculator-mobile"
        pub="pub-19"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      {/* Test Valerie:
			<SliseAd slotId="leaderboard" pub="pub-16" format="728x90" style={{ width: '728px', height: '90px' }} /> */}
      {/* Avascan:
			<SliseAd slotId="avascan" pub="pub-14" format="728x90" style={{ width: '728px', height: '90px' }} /> */}
      <Heading>728x90 banner universal:</Heading>
      <SliseAd
        slotId="8"
        pub="pub-3"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <Text>50% width:</Text>
      <div
        style={{
          width: "50%",
          aspectRatio: "728 / 90" /* paddingBottom: "12.36%" */,
        }}
      >
        <SliseAd
          slotId="888"
          pub="pub-3"
          format="728x90"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <Text>normal:</Text>
      <SliseAd
        slotId="9"
        pub="pub-3"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <SliseAd
        slotId="10"
        pub="pub-3"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <Heading>270x90 banner #1:</Heading>
      <SliseAd
        slotId="11"
        pub="pub-3"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      <br />
      <SliseAd
        slotId="12"
        pub="pub-3"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      <SliseAd
        slotId="12"
        pub="pub-3"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      <SliseAd
        slotId="12"
        pub="pub-3"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      <SliseAd
        slotId="12"
        pub="pub-3"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      <br />
      <SliseAd
        slotId="13"
        pub="pub-3"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      <br />
      <SliseAd
        slotId="blockscout-728"
        pub="pub-3"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <br />
      <SliseAd
        slotId="blockscout-270"
        pub="pub-3"
        format="270x90"
        style={{ width: "270px", height: "90px" }}
      />
      <Heading>728x90 banner #1:</Heading>
      <SliseAd
        style={{ width: "728px", height: "90px" }}
        slotId="3"
        pub="pub-3"
        format="728x90"
      />
      <Heading>728x90 banner #2:</Heading>
      <SliseAd
        style={{ width: "728px", height: "90px" }}
        slotId="4"
        pub="pub-3"
        format="728x90"
      />
      <Heading>728x90 banner #3:</Heading>
      <SliseAd
        style={{ width: "728px", height: "90px" }}
        slotId="6"
        pub="pub-3"
        format="728x90"
      />
      <Heading>728x90 banner #4:</Heading>
      <SliseAd
        slotId="7"
        pub="pub-3"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <Heading>728x90 banner #8:</Heading>
      <SliseAd
        slotId="11"
        pub="pub-3"
        format="728x90"
        style={{ width: "728px", height: "90px" }}
      />
      <Heading>498x498 banner #1:</Heading>
      <SliseAd
        pub="pub-3"
        slotId="54321"
        format="498x498"
        style={{ width: "498px", height: "498px" }}
      />
      <Text>text website content</Text>
      {"_"
        .repeat(20)
        .split("")
        .map((x, i) => (
          <Text key={"" + i}>
            first line
            <br />
            second line
          </Text>
        ))}
      <Heading>970x90 banner #2:</Heading>
      <SliseAd slotId="9876" format="970x90" />
      <Heading>Staging 970x90 banner:</Heading>
      <SliseAd pub="pub-1" slotId="100000" format="2132x328" />
    </div>
  );
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
